import styled, { device } from "@theme/utils";
export const CaseStudiesSection = styled.section`
    .swiper-slide-active {
        .content-text {
            display: block;
        }
    }
    .case-studies-slider-button {
        position: relative;
        z-index: 3;
        ${device.large} {
            max-width: 1440px;
            margin: auto;
            padding: 0px 30px;
        }
    }
    .case-studies-slider-button-prev,
    .case-studies-slider-button-next {
        background: linear-gradient(
            to right,
            #fc7318,
            #fe8415,
            #fe9515,
            #fea51a,
            #feb423
        );
        border: solid 2px transparent;
        background-image: linear-gradient(
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0)
            ),
            linear-gradient(101deg, #fc7318 0%, #feb423 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
        box-shadow: 2px 1000px 1px #f7faff inset;
        color: #fc7418;
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        border-radius: 50%;
        position: absolute;
        top: -160px;
        i {
            color: #fc7418;
        }
    }
    .case-studies-slider-button-next {
        right: 10px;
        left: auto;
    }
`;
export const CaseStudiesItemWrap = styled.div``;
import BeforeImge from "./../../../data/images/team/shape-bottom.png";
import AfterImge from "./../../../data/images/team/shape-top.png";
import Back from "./../../../data/images/bg/Productbg.png";
export const TeamSection = styled.section`
    padding: 100px 0px;
    ${device.medium} {
        padding: 100px 0px;
    }
    ${device.large} {
        padding: 100px 0px;
    }
    ${device.xlarge} {
        padding: 100px 0px;
    }
    position: relative;
    // background: #413565;
    background-image: url(${Back});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    .pattern {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
    }
    &::before {
        position: absolute;
        // right: 24em;
        bottom: 0;
        width: 100%;
        height: 0;
        content: "";
        // background-image: url(${BeforeImge});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 11%;
    }
    &::after {
        position: absolute;
        // left: 24em;
        top: 0;
        width: 100%;
        height: 0;
        content: "";
        // background-image: url(${AfterImge});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 11%;
    }
`;

import styled, { themeGet } from "@theme/utils";
export const CaseCard = styled.div``;
export const Thumb = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    background-color: #f7faff;
    margin-bottom: -5px;
`;
export const ProductCarousel = styled.div``;

import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import SectionTitle from "../../../components/title";
import OurProduct from "../../../components/our-product";
import { CaseStudiesSection, TeamSection } from "./style";

const Products = () => {
    return (
        <CaseStudiesSection>
            <TeamSection>
                <Container style={{ paddingLeft: "60px" }}>
                    <SectionTitle headingTitle="Our Products" />
                    <OurProduct />
                </Container>
            </TeamSection>
        </CaseStudiesSection>
    );
};
Products.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
            icon: PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            }),
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default Products;

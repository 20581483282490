import styled, { device } from "@theme/utils";
import blogSectionBg from "../../../data/images/blog/bg.png";
export const LatestBlogSection = styled.section`
    padding: 10px 0px;
    background: #f7faff;
    background-image: url(${blogSectionBg});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .blogcardright {
        margin-top: 0px;
        // max-height: 200px;
    }
`;

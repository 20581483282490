/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from "react-bootstrap";
import BlogCard from "../../../components/blog/blog-card";
import BlogCardTwo from "../../../components/blog/blog-card-two";
import { LatestBlogSection } from "./style";
import SectionTitleTwo from "../../../components/title-two";
import BlogInit from "./blog.json";

import axios from "axios";
import { useEffect, useState } from "react";

const LatestBlogArea = () => {
    const [Blog, setJsonData] = useState(BlogInit);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5f079a2c34c26dd0b72f1"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <LatestBlogSection>
            <Container>
                <Row>
                    <SectionTitleTwo title="Latest News From Our Blog" />
                    <Col lg={6} md={12} style={{ height: "100%" }}>
                        {Blog.slice(Blog.length - 1).map((item, i) => {
                            return (
                                <BlogCard
                                    key={i}
                                    variant="horizontal"
                                    title={item.title}
                                    image={item.image}
                                    link={item.link}
                                />
                            );
                        })}
                    </Col>
                    <Col lg={6} md={12} className="blogcardright">
                        {Blog.slice(Blog.length - 4, Blog.length - 1)
                            .reverse()
                            .map((item, i) => {
                                return (
                                    <BlogCardTwo
                                        key={i}
                                        variant="vertical"
                                        title={item.title}
                                        image={item.image}
                                        link={item.link}
                                    />
                                );
                            })}
                        <Button
                            variant="primary"
                            href="/newsArchive"
                            style={{ paddingBottom: "7px", marginLeft: "10px" }}
                        >
                            Archive
                        </Button>
                    </Col>
                </Row>
            </Container>
        </LatestBlogSection>
    );
};

LatestBlogArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            title: PropTypes.string,
        }),
        itemsone: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                image: PropTypes.shape({
                    src: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.shape({}),
                    ]).isRequired,
                    alt: PropTypes.string,
                }),
                alt: PropTypes.string,
                title: PropTypes.string,
                author: PropTypes.string,
                date: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
        itemstwo: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                image: PropTypes.shape({
                    src: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.shape({}),
                    ]).isRequired,
                    alt: PropTypes.string,
                }),
                alt: PropTypes.string,
                title: PropTypes.string,
                author: PropTypes.string,
                date: PropTypes.string,
                slug: PropTypes.string,
            })
        ),
    }),
};
export default LatestBlogArea;

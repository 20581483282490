import styled, { css, device, themeGet } from "@theme/utils";

export const Image = styled.img`
    .carousel-imgae {
        border-radius: 2px;
        maxwidth: 200px;
        maxheight: 150px;
        margin: 5% auto 5% 13%;
    }
`;

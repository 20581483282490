import styled, { css, device, themeGet } from "@theme/utils";

export const Wrap = styled.header`
    .header {
        justify-content: center;
        color: #fff;
        font-size: 30px;
        text-align: center;
        line-height: 40px;
        margin-bottom: 10px;
        padding-top: 15px;
    }
    .itemsrow {
        justify-content: center;
        margin-bottom: 10px;
        padding-bottom: 15px;
    }
    .accountsItem {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .image {
        width: 70px;
        height: 70px;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .imageText {
        color: #fff;
        font-size: 18px;
    }
`;

import { Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProductInit from "./product.json";
import { CaseCard, Thumb, CaseCardContent, Title, Desination } from "./style";

import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../preloadPage/preload";
const OurProduct = () => {
    const [preload, setPreload] = useState(true);
    const [Product, setJsonData] = useState(ProductInit);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5e6a0a2c34c26dd0b724c"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktopL: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
        },
        laptopL: {
            breakpoint: { max: 1024, min: 980 },
            items: 4,
        },
        laptopS: {
            breakpoint: { max: 980, min: 815 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 812, min: 565 },
            items: 2,
        },
        mobileL: {
            breakpoint: { max: 565, min: 0 },
            items: 1,
        },
    };

    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        return (
            <button
                onClick={() => onClick()}
                style={{
                    position: "absolute",
                    right: "-12px",
                    border: "none",
                    fontSize: "30px",
                    color: "#fc881b",
                }}
            >
                <i className="icofont-rounded-double-right"></i>
            </button>
        );
    };

    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType },
        } = rest;
        return (
            <button
                onClick={() => onClick()}
                style={{
                    position: "absolute",
                    left: "-13px",
                    border: "none",
                    fontSize: "30px",
                    color: "#fc881b",
                    zIndex: 20000,
                }}
            >
                <i className="icofont-rounded-double-left"></i>
            </button>
        );
    };

    return (
        <div>
            {preload && <Preload />}
            <Container>
                <Carousel
                    responsive={responsive}
                    containerClass="carousel-container"
                    infinite={true}
                    autoPlay={true}
                    // arrows={false}
                    customRightArrow={<CustomRightArrow />}
                    customLeftArrow={<CustomLeftArrow />}
                    style={{ padding: "40px" }}
                >
                    {Product.map((item, i) => {
                        return (
                            <div key={i}>
                                <Card
                                    style={{
                                        width: "17rem",
                                        borderRadius: "10px",
                                        height: "260px",
                                    }}
                                >
                                    <Thumb>
                                        <Card.Img
                                            style={{
                                                height: "90px",
                                                width: "90px",
                                            }}
                                            variant="top"
                                            src={item.image}
                                        />
                                    </Thumb>
                                    <Card.Body style={{ lineHeight: "25px" }}>
                                        <Card.Title
                                            style={{
                                                fontSize: "18px",
                                                lineHeight: "17px",
                                                height: "40px",
                                                marginBottom: "20px",
                                            }}
                                        >
                                            {item.title}
                                        </Card.Title>
                                        <Card.Text
                                            style={{
                                                color: "#000",
                                                lineHeight: "17px",
                                                height: "40px",
                                            }}
                                        >
                                            {item.text}
                                        </Card.Text>
                                        <Button
                                            variant="primary"
                                            href={item.link}
                                        >
                                            Read More
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        );
                    })}
                </Carousel>
            </Container>
        </div>
    );
};

export default OurProduct;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { Wrap } from "./style";

const FinancialReports = () => {
    return (
        <Wrap>
            <Container
                style={{ backgroundColor: "#43515A", maxWidth: "1500px" }}
            >
                <Row className='header'>Financial Informations And Reports</Row>
                <Row className='itemsrow'>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/price-sensetive-info'>
                            <img
                                style={{ marginLeft: "19px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024_3.png'
                                alt='4'
                            />
                            <div
                                style={{
                                    justifyContent: "center",
                                }}
                            >
                                <p className='imageText'>Price Sensitive</p>
                                <p
                                    style={{
                                        marginLeft: "5px",
                                        marginTop: "-30px",
                                    }}
                                    className='imageText'
                                >
                                    Informations
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/financial-reports/annual-reports'>
                            <img
                                style={{ marginLeft: "23px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024_4.png'
                                alt='4'
                            />
                            <p className='imageText'>Annual Reports</p>
                        </Link>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/quarterly-reports'>
                            <img
                                style={{ marginLeft: "39px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024_1.png'
                                alt='4'
                            />
                            <p className='imageText'>Quarterly Reports</p>
                        </Link>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/directors-report'>
                            <img
                                style={{ marginLeft: "25px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024_5.png'
                                alt='4'
                            />
                            <p className='imageText'>Directors Report</p>
                        </Link>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/shareholding-structure'>
                            <img
                                style={{ marginLeft: "20px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024_2.png'
                                alt='4'
                            />
                            <div
                                style={{
                                    justifyContent: "center",
                                }}
                            >
                                <p className='imageText'>Share Holding</p>
                                <p
                                    style={{
                                        marginLeft: "17px",
                                        marginTop: "-30px",
                                    }}
                                    className='imageText'
                                >
                                    Structure
                                </p>
                            </div>
                        </Link>
                    </Col>
                    <Col lg={4} md={6} sm={12} className='accountsItem'>
                        <Link to='/shareholders-info'>
                            <img
                                style={{ marginLeft: "65px" }}
                                className='image'
                                src='https://reliance.com.bd/img/img/financial%20reports%20logo/Artboard%2024.png'
                                alt='4'
                            />
                            <p className='imageText'>
                                Shareholders Information
                            </p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Wrap>
    );
};
export default FinancialReports;

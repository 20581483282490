import { jsx } from "theme-ui";
import { Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import SlidersData from "./sliders.json";
import { Image } from "./style";

import axios from "axios";
import { useEffect, useState } from "react";
const HomeSliderTop = () => {
    const [Sliders, setSliders] = useState(SlidersData);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5b36b74a4115d6ee90ab0"
        )
            .then((resultData) => {
                setSliders(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <div
            className="carousel-container"
            style={{
                backgroundImage:
                    "url(https://reliance-cdn.dev.quanticdynamics.cloud/img/home%20page%20new%20slide%20next.v/SliderBack.png)",
            }}
        >
            <Carousel variant="dark" fade="true" interval="10000">
                {Sliders.map((item, i) => {
                    return (
                        <Carousel.Item key={i}>
                            <img
                                className="carousel-image"
                                src={item.image}
                                alt={item.title}
                            />
                            <Carousel.Caption className="carousel-caption">
                                <p className="carousel-header">{item.title}</p>
                                <p className="carousel-text">{item.text}</p>
                                {item.id === "1" ? null : (
                                    <Button
                                        className="carousel-button"
                                        variant="dark"
                                        href={item.link}
                                    >
                                        Read More
                                    </Button>
                                )}
                            </Carousel.Caption>
                        </Carousel.Item>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default HomeSliderTop;

import styled, { device } from "@theme/utils";
export const BrandSingleItem = styled.a`
    // transition: all 0.4s;
    display: block;
    // height: 180px;
    position: relative;
    text-align: center;
    padding: 3px 9px;
    // @media only screen and (min-width: 1280px) {
    //     .image {
    //         height: 175px;
    //     }
    // }
    // @media only screen and (min-width: 990px) {
    //     .image {
    //         height: 170px;
    //     }
    // }
    // ${device.large} {
    //     min-width: 1290px;
    //     .image {
    //         height: 170px;
    //     }
    // }
    // ${device.medium} {
    //     max-width: 1290px;
    //     .image {
    //         height: 150px;
    //     }
    // }
`;
export const BrandItem = styled.span``;

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { BrandSingleItem, BrandItem } from "./style";
const SingleBrand = ({ item }) => {
    // console.log(item);
    //const image1 = getImage(brnadAffterImage);
    //const image2 = getImage(brnadBeforeImage);
    //console.log(image1);
    return (
        <BrandSingleItem href={item.link}>
            {/* <BrandItem className="brandBefore"> */}
            <img src={item.image} alt={item.link} className='image' />
            {/* </BrandItem> */}
            {/* <BrandItem className="brandAfter">
                <GatsbyImage image={image2} alt={alt} />
            </BrandItem> */}
        </BrandSingleItem>
    );
};
SingleBrand.propTypes = {
    brnadAffterImage: PropTypes.object,
    brnadBeforeImage: PropTypes.object,
    alt: PropTypes.string,
};
export default SingleBrand;

import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import Swiper, { SwiperSlide } from "@components/shared/swiper";
import SingleBrand from "../../../components/brand";
import SingleBrand2 from "../../../components/brand2";
import { BrandSection, BrandBox } from "./style";
const BrandArea = ({ data }) => {
    return (
        <BrandSection>
            <Container
                className='container-max-m'
                style={{ paddingTop: "10px" }}
            >
                <Row>
                    <Col>
                        <BrandBox>
                            {data && data.map && (
                                <Swiper
                                    layout={{
                                        nav: "case-studies-navigation",
                                        dots: "case-studies-dots-style",
                                    }}
                                    navigation={{
                                        nextEl:
                                            ".case-studies-slider-button-next",
                                        prevEl:
                                            ".case-studies-slider-button-prev",
                                    }}
                                    slidesPerView={4}
                                    spaceBetween={0}
                                    breakpoints={{
                                        320: {
                                            slidesPerView: 2,
                                        },
                                        480: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            slidesPerView: 4,
                                        },
                                        2920: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {/* {data?.items &&
                                    data?.items?.map((item, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <SingleBrand
                                                    brnadAffterImage={
                                                        item.images?.[0].src
                                                    }
                                                    brnadBeforeImage={
                                                        item.images?.[1].src
                                                    }
                                                    alt={item.images?.[0].alt}
                                                />
                                            </SwiperSlide>
                                        );
                                    })} */}
                                    {data.map((item, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <SingleBrand2 item={item} />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            )}
                        </BrandBox>
                    </Col>
                </Row>
                <div className='case-studies-slider-button'>
                    <div className='case-studies-slider-button-prev'>
                        <i className='icofont-rounded-double-left'></i>
                    </div>
                    <div className='case-studies-slider-button-next'>
                        <i className='icofont-rounded-double-right'></i>
                    </div>
                </div>
            </Container>
        </BrandSection>
    );
};
BrandArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                fields: PropTypes.shape({
                    slug: PropTypes.string,
                }),
                images: PropTypes.arrayOf(
                    PropTypes.shape({
                        src: PropTypes.oneOfType([
                            PropTypes.string,
                            PropTypes.shape({}),
                        ]).isRequired,
                        alt: PropTypes.string,
                    })
                ),
                alt: PropTypes.string,
            })
        ),
    }),
};
export default BrandArea;

import styled, { device, themeGet } from "@theme/utils";
import BrandBg from "../../../data/images/bg/Artboard-2.png";
export const BrandSection = styled.section`
    // max-width: 1440p;
    .swiper-slide-active {
        .content-text {
            display: block;
        }
    }
    .case-studies-slider-button {
        position: relative;
        z-index: 3;
        ${device.large} {
            max-width: 1440px;
            // margin: 0px;
            // padding: 0 0px;
        }
        // ${device.xlarge} {
        //     max-width: 1440px;
        //     margin: 200px;
        //     // padding: 0 0px;
        // }
    }
    .case-studies-slider-button-prev,
    .case-studies-slider-button-next {
        background: linear-gradient(
            to right,
            #fc7318,
            #fe8415,
            #fe9515,
            #fea51a,
            #feb423
        );
        border: solid 2px transparent;
        background-image: linear-gradient(
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0)
            ),
            linear-gradient(101deg, #fc7318 0%, #feb423 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        -webkit-box-shadow: 2px 1000px 1px #f7faff inset;
        box-shadow: 2px 1000px 1px #f7faff inset;
        color: #fc7418;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        border-radius: 50%;
        position: absolute;
        top: -110px;
        i {
            color: #fc7418;
        }
        @media only screen and (max-width: 768px) {
            top: -65px;
            width: 25px;
            height: 25px;
            line-height: 25px;
        }
        @media only screen and (max-width: 430px) {
            top: -35px;
            width: 20px;
            height: 20px;
            line-height: 19px;
            font-size: 12px;
        }
        @media only screen and (max-width: 380px) {
            top: -30px;
            width: 15px;
            height: 15px;
            line-height: 15px;
            font-size: 10px;
        }
        @media only screen and (max-width: 325px) {
            top: -25px;
            width: 10px;
            height: 10px;
            line-height: 8px;
            font-size: 8px;
        }
    }
    .case-studies-slider-button-next {
        right: 0px;
        left: auto;
    }
`;
export const BrandTitle = styled.h6`
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    span {
        font-weight: 900;
        line-height: 2.428;
        font-size: 35px;
        background: ${themeGet("colors.gradient")};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

export const BrandBox = styled.div`
    background-color: #602090;
    max-width: 1440p;
    // background-image: url(${BrandBg});
    margin-top: 10px
    padding: 5px 5px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    ${device.large} {
        max-width: 
        height: 186px;
        padding: 0px 50px 0px;
        margin: 0px 0px 0px;
    }
`;

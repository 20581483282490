/* eslint-disable prettier/prettier */
import { Container, Modal, Button } from "react-bootstrap";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import Layout from "@layout";
import SEO from "@components/seo";
import Slider from "../components/home-slider-top";

import BrandArea from "../container/home/brand";
import ThankYouSection from "../components/thank-you-section";
import FinancialReports from "../components/financial-reports";
import Products from "../container/home/products";
import LatestBlogArea from "../container/home/blog";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../data/homepages/jsondata.json";
import jsonDataForCarouselInit from "../data/others/carousel-in-home-page.js";
import jsonDataForModalInit from "../data/others/modal-window.json";

import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../components/preloadPage/preload";

const IndexPage = ({ data }) => {
    const [modalShow, setModalShow] = useState(false);
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [jsonDataForCarousel, setJsonDataForCarousel] = useState(
        jsonDataForCarouselInit
    );
    const [jsonDataForModal, setJsonDataForModal] = useState(
        jsonDataForModalInit
    );
    const [preload, setPreload] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // console.log(jsonDataForModal);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a5d1e11cc22a296f1f6805"
        )
            .then(resultData => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch(err => {
                console.log(err);
                setPreload(false);
            });
        setModalShow(true);
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62b235593cf17b0b123258c2"
        )
            .then(resultData => {
                setJsonDataForCarousel(resultData.data);
            })
            .catch(err => {
                console.log(err);
            });
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62b2ee7a39095093d34536ac"
        )
            .then(resultData => {
                setJsonDataForModal(resultData.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page.content || []);
    // content["brand-section"]?.items.forEach((item) => {
    //     console.log(item);
    // });
    // console.log(jsonDataForCarousel);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {preload && <Preload />}
            <SEO title='Home' pathname='/' />
            {jsonDataForModal
                .slice(jsonDataForModal.length - 1)
                .map((item, i) => {
                    return (
                        <Modal
                            key={i}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size='lg'
                            aria-labelledby='contained-modal-title-vcenter'
                            centered
                            style={{ justifyContent: "center" }}
                        >
                            <Modal.Header closeButton>
                                <div
                                    style={{
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    <h3> {item.title}</h3>
                                </div>
                            </Modal.Header>
                            <Link to={item.link} className='logo'>
                                <img
                                    className='popupImg'
                                    src={item.image}
                                    alt='event'
                                    // style={{
                                    //     borderRadius: "7px",
                                    // }}
                                />
                            </Link>
                        </Modal>
                    );
                })}
            <Container>
                <Slider />
            </Container>
            <BrandArea data={jsonDataForCarousel} />
            <ThankYouSection
                image={jsonData.image}
                name='thankyousection'
                designation='image'
                title={jsonData.title}
                disc1={jsonData.text}
                disc2={jsonData.text1}
                disc3={jsonData.text2}
            />
            <Container>
                <Products />
            </Container>
            <Container>
                <FinancialReports />
            </Container>
            <LatestBlogArea />
        </Layout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allCaseStudies: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allServices: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        latestPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredPosts: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query homePageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "home" }, pageType: { eq: homepage }) {
            content {
                ...PageContentAll
                section_title {
                    ...SectionTitle
                }
            }
        }
        allCaseStudies(
            sort: { order: DESC, fields: id }
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...CaseStudies
            }
        }
        allServices(
            limit: 4
            sort: { order: DESC, fields: id }
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...Services
            }
        }
        featuredPosts: allArticle(
            limit: 1
            sort: { order: DESC, fields: date }
        ) {
            nodes {
                tags {
                    title
                    slug
                }
                slug
                title
                date(formatString: "D MMMM, YYYY")
                categories {
                    title
                    slug
                }
                image {
                    alt
                    src {
                        childImageSharp {
                            gatsbyImageData(
                                height: 200
                                quality: 100
                                width: 520
                            )
                        }
                    }
                }
            }
        }
        latestPosts: allArticle(limit: 3, sort: { order: DESC, fields: date }) {
            nodes {
                ...Articles
            }
        }
    }
`;

export default IndexPage;
